<div class="mt-1 pt-0 px-2">
  <div class="pb-3 px-2">
    <div class="row">
      <div mat-dialog-title class="d-flex justify-content-center">
        <div class="customtitle-logs">
          <h2 class="fw-bold mb-0  text-start weig_700" style="margin: 8px;">
            {{'Session Expiration Warning' | translate}}
          </h2>
        </div>
      </div>
    </div>
    <mat-dialog-content class="mat-typography mt-3 d-flex" style="overflow-y: hidden;">
      <div class=" text-center">
        <p class="text-dark" style="margin: 8px; font-weight: 500; font-family: Inter;">{{'Your session will expire in 5 minutes. Do you want to logout or continue?' |
          translate}}</p>
      </div>
    </mat-dialog-content>
    <div mat-dialog-actions class="justify-content-center ps-2">
      <div class="filter_btn justify-content-senter" style="margin: 8px;">
        <button type="submit" (click)="onLogout()" mat-button Class=" rounded-pill px-5 mb-4 py-2 clear_button1 text-light">
          <span style="color: white;">{{'Logout' | translate}}</span>
        </button>
        <button type="submit" (click)="onContinue()" mat-button Class=" rounded-pill px-5 mb-4 py-2 add_button1 text-light">
          <span style="color: white;">{{'Continue' | translate}}</span>
        </button>
      </div>
    </div>
  </div>
</div>