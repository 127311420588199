import { Component,ViewChild,inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router, ActivatedRoute } from '@angular/router';
import { AdminsService } from 'src/app/services/Admins/admins.service';

export interface ViewContact {
  modifiedBy: number;
  createdBy: number;
  name: string,
  firstName: string,
  lastName: string,
  email: string;
  phoneNumber: number;
  mobileNumber: number;
  whatsapp: number,
  districtId: number,
  muncipalityId: number,
  parishId: number,
  zone: string,
  streetName: string,
  doorNo: number,
  floorNo: string,
  doorIdentifier: number,
  zipCode: string,
  userId: number,
  roleId: number,

}

@Component({
  selector: 'app-contactview',
  templateUrl: './contactview.component.html',
  styleUrls: ['./contactview.component.scss']
})
export class ContactviewComponent {
  selectedTabIndex: number = 0;
  // loggedUserId: number = 0;
  // loggedUser: any;

  RoutedContactId: number = Number();
  CompanyDecodedData: any;

  roles: any[] = [];

  RoutedContactDecodedData: any;
  private activatedRoute = inject(ActivatedRoute);

  ContactData: ViewContact | null = null;

  constructor(public dialog: MatDialog, private router: Router,
     private usersService: AdminsService){
      try {
        var decodedData = atob(this.activatedRoute.snapshot.params['id']);
        this.CompanyDecodedData = JSON.parse(decodedData);
        if (!(this.CompanyDecodedData?.id > 0)) { this.router.navigate(['contact']) }
      } catch (error) {
        this.router.navigate(['contact'])
      }
      this.RoutedContactDecodedData = this.CompanyDecodedData;

      this.selectedTabIndex = this.RoutedContactDecodedData.tabIndex - 1;
      this.RoutedContactId = this.RoutedContactDecodedData.id;
      this.usersService.GetContactById(this.RoutedContactId).subscribe((res: any) => { this.ContactData = res; }, err => { })
      this.GetAllContacts();
    }
    GetAllContacts() {
      this.usersService.GetAllContacts<ViewContact[]>().subscribe((res: ViewContact[]) => {
      }, (err: any) => { })
    }

  gotoback() { this.router.navigate(['/contact']) }

}
