import { Component, Inject, Optional } from '@angular/core';
import {MatDialog,MAT_DIALOG_DATA,MatDialogRef,MatDialogTitle,MatDialogContent,MatDialogActions,MatDialogClose,} from '@angular/material/dialog';
import { MyToursComponent } from '../my-tours.component';
import { AdminsService } from 'src/app/services/Admins/admins.service';
import { AlertService } from 'src/app/services/Alert/alert.service';
import { TranslateService } from '@ngx-translate/core';

export interface GetTourListingData
{
  starttime: Date;
  endtime: Date;
  timeSlot: string;
  date:Date;
  id : number;
  tourListingId : number;
}



@Component({
  selector: 'app-reject-tour',
  templateUrl: './reject-tour.component.html',
  styleUrls: ['./reject-tour.component.scss']
})

export class RejectTourComponent {
  data: GetTourListingData;
  tourId : number;
  AcceptourData: any;
  stepper: any;

  constructor(public closing: MatDialogRef<MyToursComponent>,private translate: TranslateService,

    public dialogRef: MatDialogRef<RejectTourComponent>,private alertservice: AlertService,
     @Optional() @Inject(MAT_DIALOG_DATA) public tourData: GetTourListingData[],
  private tourService : AdminsService){

    if (Array.isArray(tourData)) {
      this.data = tourData[0];
    } else {
      this.data = tourData;
    }
    console.log('Assigned data:', this.data);

  }
  CloseIcon() {
    this.closing.close()
  }

  RejectTour(rowData : GetTourListingData)
  {
    this.tourService.RejectTourByTourId(rowData.tourListingId).subscribe((res : any) => {
      this.AcceptourData = res;
      this.stepper.selectedIndex = this.stepper.selectedIndex + 1;
      // this.alertservice.default('Your tour request has been Rejected as per your request')
    })
    this.dialogRef.close('rejected');
    // location.reload();
    // //Todo:ErroMessage //
 this.alertservice.error ( this.translate.instant('YourtourrequesthasbeenRejectedasperyourrequest'))
  }

}
