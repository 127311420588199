import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-session-expiration-dialog',
  templateUrl: './session-expiration-dialog.component.html',
  styleUrls: ['./session-expiration-dialog.component.scss']
})
export class SessionExpirationDialogComponent {
  
  constructor(private dialogRef: MatDialogRef<SessionExpirationDialogComponent>) {}

  // Method to handle logout action
  onLogout() {
    this.dialogRef.close('logout'); // Close the dialog and return 'logout'
  }

  // Method to handle continue action
  onContinue() {
    this.dialogRef.close('continue'); // Close the dialog and return 'continue'
  }
}