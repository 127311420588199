import { CanActivate, Router } from '@angular/router';
import { Injectable, Output, EventEmitter } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate {
    @Output() isLoggedIn: EventEmitter<any> = new EventEmitter();
    constructor(private router: Router) { }

    canActivate(): Observable<boolean> {
        var token = localStorage.getItem("LoggedUserToken");
        if (token) {
            this.isLoggedIn.emit(true);
            return of(true);
        } else {
            this.logout();
            this.isLoggedIn.emit(false);
            return of(false);
        }
    }

    logout() {
        this.deleteUserData();
        this.isLoggedIn.emit(false);
        if(window.location.href.indexOf(window.location.host+"/email-request/")>0){
            return
        }else{
        this.router.navigate(['/login'])
        }
    }

    deleteUserData() {
        const savedLang=localStorage.getItem("lang");
        localStorage.clear(); 
        if (savedLang){
            localStorage.setItem("lang", savedLang)
        } 
        sessionStorage.clear();
    }
}